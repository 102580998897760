<template>
  <v-container fluid style="background-color:#f2f2f2 !important;min-height:100%;">
    <v-row>
      <v-col cols="12">
        <v-card flat style="border-radius:8px;min-height:320px;">
          <v-card-title
            class="py-2 px-2"
            dark
            style="color:teal;border-bottom: 1px solid teal;"
          >
            <v-btn
              color="teal"
              dark
              class="text-none mr-2 mb-2"
              @click="showCreateDept"
            >
              <v-icon small class="mr-2">mdi-plus</v-icon>
              Thêm thiết bị
            </v-btn>
            <template
              v-if="selected_properties && selected_properties.length > 0"
            >
              <v-btn
                color="orange"
                dark
                class="text-none mr-2 mb-2"
                @click="lockProperties('locks')"
              >
                <v-icon small class="mr-2">mdi-lock-outline</v-icon>
                Khóa
              </v-btn>

              <v-btn
                color="green"
                dark
                class="text-none mr-2 mb-2"
                @click="lockProperties('unlocks')"
              >
                <v-icon small class="mr-2"
                  >mdi-lock-open-variant-outline</v-icon
                >
                Mở Khóa
              </v-btn>

              <v-btn
                color="red"
                dark
                class="text-none mr-2 mb-2"
                @click="deleteProperties"
              >
                <v-icon small class="mr-2">mdi-delete-forever</v-icon>
                Xóa
              </v-btn>
              
            </template>
            <v-spacer />
            <v-btn class="mr-2 text-none px-1" text color="teal"
              to="/kho-monitor">
              <u>Giám sát kho</u>
            </v-btn>
            <v-btn class="mr-2 text-none px-1" text color="teal"
              to="/equipments-on-lend">
              <u>Ds đang cho mượn</u>
            </v-btn>
            <v-text-field
              class="mb-2"
              v-model="searchString"
              append-icon="mdi-magnify"
              label="Search"
              clearable outlined dense
              single-line hide-details
              :style = "{'width': $vuetify.breakpoint.smAndDown?'100%':'220px'}"
            ></v-text-field>
          </v-card-title>

          <v-card-text class="pa-0 pt-2" style="min-height:300px;">
            <v-data-table
              flat
              v-model="selected_properties"
              :headers="headers"
              :items="properties"
              :loading="loading"
              loading-text="Loading... Please wait"
              no-data-text="Chưa có thiết bị nào"
              item-key="_id"
              :mobile-breakpoint="0"
              :items-per-page="pageSize"
              show-select dense
              class="elevation-0"
              :search="searchString"
              :page.sync="depsPage"
              @page-count="pageCount = $event"
              hide-default-footer
            >
              <template v-slot:item.name="{ item }">
                <a :href="'/equipts-detail/' + item._id"
                  style="font-size:15px;">
                  {{ item.name }}
                </a>
              </template>
              <template v-slot:item.enable="{ item }">
                <v-icon v-if="!item.enable">
                  mdi-lock
                </v-icon>
              </template>
            </v-data-table>
            <div v-if="pageCount > 1" class="text-center pt-2">
              <v-pagination
                v-model="depsPage"
                :length="pageCount"
              ></v-pagination>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      token: "token",
    }),
  },
  data() {
    return {
      roles: ['equipments-manager', 'admin'],
      properties: [],
      selected_properties: [],
      pageSize: 15,
      pageCount: 0,
      depsPage: 1,
      loading: false,
      searchString: "",
      headers: [
        {
          text: "Tên Thiết Bị",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "QR Code",
          align: "start",
          sortable: true,
          value: "qr_code",
        },
        {
          text: "Trạng thái",
          align: "start",
          sortable: true,
          value: "status",
        },
        {
          text: "Chủ sở hữu",
          align: "start",
          sortable: true,
          value: "owner",
        },
        {
          text: "Khóa",
          align: "start",
          sortable: true,
          value: "enable",
          width: 80,
        },
      ],
    };
  },
  methods: {
    showCreateDept() {
      this.$router.push({path: '/equipts-detail/0'})
    },
    fetchProperties() {
      let self = this;
      self.loading = true;
      self.selected_properties = [];
      let paramsProperties = {
        // created_at_min: moment(self.params.timeRange.start).toISOString(),
        // created_at_max: moment(self.params.timeRange.end).toISOString(),
        // show_deleted: true
        type: 'thiet-bi'
      };
      self.axios
        .get(self.$root.apiAssetMana + "/properties", {
          headers: { "x-auth": this.token },
          params: paramsProperties,
        })
        // + 'owners='+self.$root.site_id
        .then((res) => {
          if (res.data.status == "OK") {
            console.log(res.data.content.items);
            self.properties = res.data.content.items;
            self.properties = self.properties.filter(i => i.type == 'thiet-bi')
            // self.properties = res.data.content;
          } else {
            console.log(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          self.loading = false;
        });
    },
    deleteProperties() {
      if (confirm("Bạn có chắc muốn xóa các mục đã chọn ?")) {
        if (!this.selected_properties || this.selected_properties.length <= 0)
          return;
        let self = this;
        this.axios
          .delete(
            self.$root.apiAssetMana + "/properties/deletes",
            {
              headers: { "x-auth": this.token },
              data: {
                ids: this.selected_properties.map((u) => u._id)
              },
            }
          )
          .then((res) => {
            if (res.data.status == "OK") {
              alert("Xóa thành công!")
              self.fetchProperties();
            } else {
              console.log(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      // console.log(this.selected_properties)
    },
    lockProperties(lockFunction) {
      // console.log(this.selected_properties)
      if (!this.selected_properties || this.selected_properties.length <= 0)
        return;
      let self = this;
      this.axios
        .put(
          self.$root.apiAssetMana + "/properties/" + lockFunction,
          {
            ids: this.selected_properties.map((u) => u._id),
          },
          { headers: { "x-auth": this.token } }
        )
        .then((res) => {
          if (res.data.status == "OK") {
            self.fetchProperties();
          } else {
            console.log(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  mounted() {
    if (this.$vuetify.breakpoint.smAndDown){
      this.headers = this.headers.filter(h => h.value!='type')
    }
    this.$nextTick(() => {
      this.fetchProperties();
    });
  },
};
</script>

<style></style>
